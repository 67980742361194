import { render, staticRenderFns } from "./best-freelancer.html?vue&type=template&id=05aafe05&scoped=true&"
import script from "./best-freelancer.js?vue&type=script&lang=js&"
export * from "./best-freelancer.js?vue&type=script&lang=js&"
import style0 from "./best-freelancer.scss?vue&type=style&index=0&id=05aafe05&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05aafe05",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VIcon,VImg,VRow})
